import React, {FC} from 'react';
import Section from '../../../components/section';
import {PageHero} from '../../../components/page-hero';
import Layout from '../../../components/layout/layout';
import DiscoverProfile from '../../../components/tools-api-requests/discover-profile';
import Breadcrumb from '../../../components/breadcrumb';

const ApiDocumentationProfile: FC = () => {
  return (
    <Layout>
      <PageHero title="" titleSmall minHeight id="seo_home_area_automations">
        <Breadcrumb
          items={[
            {
              to: `/automations`,
              name: 'Automations',
              active: false,
            },
            {
              to: `/automations/built-in-automations`,
              name: 'Built-in automations',
              active: false,
            },
            {
              to: `/automations/research`,
              name: 'Research',
              active: false,
            },
            {
              to: `/automation/discover-profile/`,
              name: 'Discover Profile',
              active: false,
            },
            {
              to: `/automation/discover-profile/api-documentation`,
              name: 'API documentation',
              active: true,
            },
          ]}
        />
      </PageHero>
      <PageHero
        title="Discover Profile API documentation"
        subtitle="This page provides API documentation for Discover Profile"
        titleSmall
        minHeight
        id="seo_home_area_automations"
      ></PageHero>

      <Section>
        <DiscoverProfile />
      </Section>
    </Layout>
  );
};

export default ApiDocumentationProfile;
