import React from 'react';
import './styles.scss';

const DiscoverProfile = () => {
  return (
    <div className="tools-api-container">
      <h4 className="my-4 info-title" id="request-discover-profile">
        To make a request for Discover Profile result use this POST API.
      </h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">POST</span>{' '}
          https://api.hexomatic.com/tool-api/discover-profile?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexomatic{' '}
                <a className="info-title" href="https://dash.hexomatic.com/settings" no-index>
                  settings{' '}
                </a>
                API/Webhook section and copy your key
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Request Header</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">Content-Type</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">application/json</p>
            </td>
          </tr>
        </tbody>
      </table>

      <h5>Example</h5>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"Content-Type": "application/json"</p>
        <p>{'}'}</p>
      </pre>

      <h5>Request Body</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">source</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">you should provide email or username</p>
            </td>
          </tr>
          {/* <tr>
            <td className="td-fill">isAdult</td>
            <td>
              <p className="bold-text">boolean</p>
              <p className="normal-text">
                you should provide true or false to choose to include or exclude adult websites. The default value is false.
              </p>
            </td>
          </tr> */}
          {/* <tr>
            <td className="td-fill">proxy</td>
            <td>
              <p className="bold-text"> object (*Optional)  </p>
              <p className="normal-text">you can provide special proxy settings </p>
              <p className="normal-text">
                proxy: {'{'} "type": "premium", "country_code": "US" {'}'}
              </p>
            </td>
          </tr> */}
        </tbody>
      </table>
      {/* <h5>Possible values for the fields</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">proxy type</td>
            <td>
              <p className="normal-text">"", "premium", "unmetered"</p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">proxy country_code</td>
            <td>
              <p>country_code is required if the type is equal to "premium"</p>
              <p>List of country codes:</p>
              <p className="normal-text">
                {' '}
                "AF", "AL", "DZ", "AD", "AO", "AR", "AU", "AT", "AZ", "BH", "BD", "BB", "BY", "BJ", "BO", "BW", "BR",
                "BG", "BF", "BI", "KH", "CM", "CA", "CV", "CL", "CN", "CO", "CG", "CR", "HR", "CU", "CY", "CZ", "CI",
                "DK", "DO", "EC", "EG", "SV", "EE", "ET", "FJ", "FI", "FR", "GM", "GE", "DE", "GH", "GR", "GL", "GT",
                "GN", "HT", "HN", "HK", "HU", "IN", "ID", "IR", "IQ", "IL", "IT", "JM", "JP", "JO", "KE", "KZ", "KR",
                "KW", "KG", "LV", "LB", "LT", "MO", "MK", "MG", "MW", "MY", "MV", "ML", "MT", "MR", "MU", "MX", "MD",
                "MN", "MA", "MZ", "MM", "NA", "NP", "NL", "NI", "NE", "NG", "PK", "PA", "PY", "PE", "PH", "PL", "PT",
                "PR", "QA", "RO", "RU", "RW", "RE", "LC", "SM", "SA", "SN", "RS", "SL", "SG", "SK", "SI", "SO", "ZA",
                "ES", "LK", "SD", "SE", "TZ", "TH", "TG", "TT", "TN", "TR", "UG", "UA", "AE", "GB", "US", "UY", "UZ",
                "VE", "VN", "ZW"
              </p>
            </td>
          </tr>
        </tbody>
      </table> */}
      <h5>Example</h5>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"source": "example@gmail.com",</p>
        {/* <p className="mgl-25">"isAdult": true,</p> */}
        {/* <p className="mgl-25">"proxy": {'{'}</p>
        <p className="mgl-25">        "type": "",</p>
        <p className="mgl-25">        "country_code": ""</p>
        <p className="mgl-25">{'}'}</p>*/}
        <p>{'}'}</p>
      </pre>
      <h5>Response Body</h5>
      <p>
        if request is <strong className="info-title">successful</strong> you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"taskId": "13f4b463-7e8a-429a-b62b-4afde618a154"</p>
        <p>{'}'}</p>
      </pre>

      <p>
        if request is <strong className="danger-color">not</strong> successful you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"statusCode": 403,</p>
        <p className="mgl-25">"message": "Forbidden"</p>
        <p>{'}'}</p>
      </pre>

      <h4 className="my-4 info-title" id="get-discover-profile">
        To get the result for Discover Profile use this GET API.
      </h4>
      <h5>HTTP Request</h5>
      <pre className="pre">
        <p>
          <span className="btn-pre">GET</span>{' '}
          https://api.hexomatic.com/tool-api/result/&#123;TASK_ID&#125;?key=&#123;YOUR_API_KEY&#125;
        </p>
      </pre>
      <h5>Parameters</h5>
      <table>
        <thead>
          <tr>
            <th>Fields</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-fill">YOUR_API_KEY</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                to get YOUR_API_KEY visit hexomatic{' '}
                <a href="https://dash.hexomatic.com/settings" no-index>
                  settings
                </a>{' '}
                and API/Webhook section and copy your key
              </p>
            </td>
          </tr>
          <tr>
            <td className="td-fill">TASK_ID</td>
            <td>
              <p className="bold-text">string</p>
              <p className="normal-text">
                {' '}
                if you did Discover Profile POST request you must have received <strong>taskId</strong> that is valid
                one month after making the POST request. You can try to make this GET request with apx 1 minute
                frequency after making the POST request for the tool.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>Response Body</h5>
      <p>if request is successful you will get</p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"result": {'{'}</p>
        <p className="mgl-50"> "_discover_profile": {'['} </p>
        <p className="mgl-75">{'{'}</p>
        <p className="mgl-100"> "_discover_profile_input": {'["exampleInput"]'}, </p>
        <p className="mgl-100"> "_discover_profile_source": {'["Example1"]'}, </p>
        <p className="mgl-100"> "_discover_profile_url": {'["https://www.example1.com/exampleInput"]'} </p>
        <p className="mgl-100"> "_discover_profile_category": {'["Technology"]'},</p>
        <p className="mgl-75">{'}'},</p>
        <p className="mgl-75">{'{'}</p>
        <p className="mgl-100"> "_discover_profile_source": {'["Example2"]'}, </p>
        <p className="mgl-100"> "_discover_profile_url": {'["https://www.example2.com/people/exampleInput"]'} </p>
        <p className="mgl-100"> "_discover_profile_category": {'["Online Community"]'},</p>
        <p className="mgl-75">{'}'},</p>
        <p className="mgl-75">{'{'}</p>
        <p className="mgl-100"> "_discover_profile_source": {'["Example3"]'}, </p>
        <p className="mgl-100"> "_discover_profile_url": {'["https://www.example3.com/User:exampleInput"]'} </p>
        <p className="mgl-100"> "_discover_profile_category": {'["Education"]'}, </p>
        <p className="mgl-75">{'}'}</p>
        <p className="mgl-50">{']'}</p>
        <p className="mgl-25">{'}'}</p>
        <p>{'}'}</p>
      </pre>
      <p>
        if request is <strong className="danger-color">not</strong> successful you will get
      </p>
      <div className="json-title-block">
        <span>JSON representation</span>
      </div>
      <pre className="pre">
        <p>{'{'}</p>
        <p className="mgl-25">"statusCode": 403,</p>
        <p className="mgl-25">"message": "Forbidden"</p>
        <p>{'}'}</p>
      </pre>
    </div>
  );
};

export default DiscoverProfile;
